import React from 'react';
import ImportHistory from 'pages/[type]/imports';
interface Props {
  searchTerm: string;
}

const CatalogSection = ({ searchTerm }: Props) => {
  return <ImportHistory searchTerm={searchTerm} />;
};

export default CatalogSection;
