import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { ImportRoutes } from 'constants/routes';
import MainContentLayout from 'components-shared/MainContentLayout';
import SubHeader from 'components-shared/header/SubHeader';
import { ROLE_BRAND_ACQUISITION } from 'constants/common';
import { useStore } from 'store';
import {
  BreadCrumbs,
  Title,
  Action,
  TabNavigation,
  TabSection,
} from '../components/productListing/Catalog';

const MainHome = styled.main(
  ({ theme: { colors } }) => css`
    background-color: ${colors.neutral20};
  `
);

const Home = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const { auth } = useStore();
  const { query, push } = useRouter();
  const { catalogId } = query as { catalogId: string };
  const { activeTab } = query as { activeTab: string };

  const isBrandAquisition = auth.hasRole(ROLE_BRAND_ACQUISITION);

  useEffect(() => {
    if (activeTab === 'activity') {
      setTabIndex(1);
    }
  }, [activeTab]);

  const handleTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    index: number
  ) => {
    setTabIndex(index);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleClickImport = () => {
    push({
      pathname: ImportRoutes.Import,
      query: { catalogId },
    });
  };

  return (
    <MainHome>
      <MainContentLayout
        above={
          <SubHeader
            breadcrumbs={
              <>{isBrandAquisition && <BreadCrumbs catalogId={catalogId} />}</>
            }
            title={<Title catalogId={catalogId} />}
            action={
              <Action
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handleClick={handleClickImport}
              />
            }
            subNavigation={
              <TabNavigation
                tabIndex={tabIndex}
                handleTabChange={handleTabChange}
              />
            }
          />
        }
        left={<TabSection searchTerm={searchTerm} tabIndex={tabIndex} />}
        reverseMiddleOnMobile
      />
    </MainHome>
  );
};

Home.getInitialProps = async () => {
  return {};
};

export default Home;
