import React, { useState } from 'react';
import { Button, Flex, FlexProps } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import { InputGroup } from 'styles';
import Icon from 'components-shared/icon/Icon';
import { StyledInputWithFloatingLabel } from 'components-shared/Input';
import { StyledFloatingLabel } from 'components-shared/Label';
import { StyleColor } from 'models/utils';

const SearchBarWrapper = styled(Flex)(
  ({ theme: { colors, radii } }) => css`
    align-items: center;
    border-radius: ${radii.lg};
    color: ${colors.neutral120};
    border: 1px solid ${colors.neutral120};
  `
);

const StyledSearchButton = styled(Button)(({ theme: { colors, space } }) => ({
  color: colors.neutral120,
  backgroundColor: 'transparent',
  outline: 'none',
  borderRadius: '50%',
  padding: space[8],
  transition: '300ms all',
  '&:hover': {
    backgroundColor: colors.neutral20,
    cursor: 'pointer',
  },
  '&:active': {
    backgroundColor: colors.neutral60,
  },
  '&:disabled': {
    color: colors.neutral60,
    cursor: 'default',
  },
}));

export interface SearchInputProps extends FlexProps {
  currentSearchTerm?: string;
  handleSearch: (searchTerm: string) => void;
  placeholderText: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  currentSearchTerm,
  handleSearch,
  placeholderText,
  ...containerProps
}) => {
  const [searchTermInput, setSearchTermInput] = useState('');
  const { theme } = useTheme();

  const onSearch = (searchTerm: string, event?: KeyboardEvent) => {
    if (
      searchTermInput.length === 1 &&
      event?.type === 'keydown' &&
      event?.key === 'Backspace'
    ) {
      handleSearch('');
    }

    if (
      searchTerm === currentSearchTerm ||
      (event?.type === 'keydown' && event?.key !== 'Enter')
    ) {
      return;
    }
    if (searchTerm.trim().length) {
      handleSearch(searchTerm);
    }
  };

  const reset = () => {
    setSearchTermInput('');
    handleSearch('');
  };

  return (
    <SearchBarWrapper {...containerProps} data-testid="search-wrapper">
      <InputGroup flex="2">
        <StyledInputWithFloatingLabel
          aria-label={placeholderText}
          name="searchTerm"
          type="text"
          value={searchTermInput}
          placeholder={placeholderText}
          onKeyDown={(e) => onSearch(searchTermInput, e)}
          onChange={({ target: { value } }) => setSearchTermInput(value)}
          sx={{ border: 'none !important' }}
        />
        <StyledFloatingLabel>{placeholderText}</StyledFloatingLabel>
      </InputGroup>
      <Flex>
        {Boolean(searchTermInput) && (
          <StyledSearchButton onClick={reset}>
            <Icon
              name="close"
              size={16}
              color={theme.colors.neutral80 as StyleColor}
            />
          </StyledSearchButton>
        )}
        <StyledSearchButton onClick={() => onSearch(searchTermInput)}>
          <Icon
            name="search"
            size={20}
            color={theme.colors.neutral120 as StyleColor}
          />
        </StyledSearchButton>
      </Flex>
    </SearchBarWrapper>
  );
};

export default SearchInput;
