import { isSSR } from './next';

export function saveBlob(data: Blob, filename: string) {
  if (isSSR()) return;
  const url = window.URL.createObjectURL(data);

  const hiddenDownloadLink = document.createElement('a');
  hiddenDownloadLink.setAttribute('href', url);
  hiddenDownloadLink.setAttribute('download', filename);
  document.body.appendChild(hiddenDownloadLink);
  hiddenDownloadLink.click();
  hiddenDownloadLink.parentNode?.removeChild(hiddenDownloadLink);
}

export const downloadFileFromURL = (fileUrl: string): void => {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = fileUrl;
  const fileName = fileUrl.split('/').pop();

  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(fileUrl);
  a.remove();
};
