import { PaginationFields } from 'models';
import { getProducts } from 'api/services/productService';
import useCancelToken from 'hooks/useCancelToken';
import { getKebabCase } from 'utils/primitives';
import usePageableTableRequest from 'hooks/usePageableTableRequest';

export type Props = {
  pagination: PaginationFields;
  vendorRef?: string;
};

const useProductsList = ({ pagination, vendorRef }: Props) => {
  const { getCancelToken } = useCancelToken();

  async function fetchProductsList() {
    return await getProducts(
      {
        pagination,
        cancelToken: getCancelToken(true),
      },
      vendorRef
    );
  }

  const data = usePageableTableRequest({
    queryKey: getKebabCase(vendorRef, 'table', ...Object.values(pagination)),
    fetcher: fetchProductsList,
  });

  return data;
};

export default useProductsList;
