import styled, { css } from 'styled-components';
import { Input } from '@rebass/forms/styled-components';

const StyledInput = styled(Input)(
  ({ theme: { colors, radii, space, up, fontSizes } }) => css`
    border-radius: ${radii.md};
    border: 1px solid;
    border-color: ${colors.neutral120};
    padding-left: ${space[10]};
    font-size: ${fontSizes[14]};
    &:disabled {
      background-color: ${colors.neutral40};
      border-color: ${colors.neutral40};
    }
    ${up('sm')} {
      height: 40px;
    }
    ${up('md')} {
      height: 48px;
    }
  `
);

export const StyledInputWithFloatingLabel = styled(StyledInput)(
  ({ theme: { colors, fontSizes, up } }) => css`
    &::placeholder {
      opacity: 0;
    }
    &:not(:placeholder-shown) + label,
    &:focus + label {
      cursor: default;
      font-size: ${fontSizes[12]};
      color: ${colors.neutral80};
      ${up('sm')} {
        transform: translate3d(0, -150%, 0);
      }
      ${up('md')} {
        transform: translate3d(0, -180%, 0);
      }
      background-color: ${colors.neutral00} !important;
    }
    &:focus {
      outline: none !important;
      border: 2px solid;
      border-color: ${colors.summer100};
    }
  `
);
