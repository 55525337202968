import React, { useMemo, useState } from 'react';
import { EmptyState, StateType } from '@tamo-marketplace/genie';
import { BaseTableStyle } from 'styles';
import { Styleable } from 'models/utils';
import {
  EMPTY_RESULTS_SUBTITLE,
  EMPTY_RESULTS_TITLE,
} from 'constants/textResources';
import { Column } from 'react-table';
import { ExportsAggregatorResponse } from 'models';
import BaseTable from 'components-shared/table/BaseTable';
import { useRouter } from 'next/router';
import ExportModal from './modal/ExportModal';
import ColumnNameContent from './tableContent/ColumnNameContent';
import ColumnStatus from './tableContent/ColumnStatus';
import useProductReportsByVendors from 'hooks/products/useProductReportsByVendors';

const NoDataEmptyState = () => (
  <EmptyState
    stateType={StateType.EMPTY_RESULTS}
    title={EMPTY_RESULTS_TITLE}
    subtitle={EMPTY_RESULTS_SUBTITLE}
  />
);

type TableProps = {
  searchTerm?: string;
} & Styleable;

const ExportListingTable = ({ style, searchTerm, ...props }: TableProps) => {
  const { query } = useRouter();
  const { catalogId } = query as { catalogId: string };

  const [exportModalOpen, setExportOpen] = useState<{
    show: boolean;
    report?: ExportsAggregatorResponse | null;
  }>({ show: false, report: null });

  const { reports, isLoading } = useProductReportsByVendors({
    catalogId,
  });

  const openExportModal = (report: ExportsAggregatorResponse) => {
    setExportOpen({ show: true, report });
  };

  const closeExportModal = () => {
    setExportOpen({ show: false, report: null });
  };

  const tableColumns: Column<ExportsAggregatorResponse>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 30,
        Cell: ({ row: { original: report } }) => (
          <p title={report.status}>{report.id}</p>
        ),
      },
      {
        Header: 'Name',
        accessor: 'reportUrl',
        Cell: ({ row: { original: report } }) => (
          <ColumnNameContent
            onClick={() => openExportModal(report)}
            text={report.reportUrl}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original: report } }) => (
          <ColumnStatus status={report.status} />
        ),
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ row: { original: report } }) => (
          <p title={report.createdAt}>{report.createdAt}</p>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ExportModal
        isOpen={exportModalOpen.show}
        onClose={closeExportModal}
        data={exportModalOpen?.report}
      />
      <BaseTableStyle sx={style} data-testid="export-table-wrapper" {...props}>
        <BaseTable<ExportsAggregatorResponse>
          loading={isLoading}
          totalRows={10}
          tableOptions={{
            data: reports,
            columns: tableColumns,
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: 0,
          }}
          NoDataEmptyState={NoDataEmptyState}
          searchTerm={searchTerm}
          paginated={false}
        />
      </BaseTableStyle>
    </>
  );
};

export default ExportListingTable;
