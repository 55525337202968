import {
  ENDPOINTS,
  getListOfProductReportsByVendor,
} from 'api/services/productReportsByVendor';
import { ApiResponse, ExportsAggregatorResponse } from 'models';
import { Nullable } from 'models/utils';
import useSWR, { SWRConfiguration } from 'swr';
import { MutatorCallback } from 'swr/dist/types';

export type useProductReportsByVendorsProps = {
  catalogId: string;
  config?: SWRConfiguration;
};

export interface useProductReportsByVendorsResponse {
  reports?: ExportsAggregatorResponse[] | null;
  isLoading: boolean;
  isError: boolean;
  mutate?: (
    data?:
      | ApiResponse<ExportsAggregatorResponse[]>
      | Promise<ApiResponse<ExportsAggregatorResponse[]>>
      | MutatorCallback<ApiResponse<ExportsAggregatorResponse[]>>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<Nullable<ApiResponse<ExportsAggregatorResponse[]>>>;
}

const useProductReportsByVendors = ({
  catalogId,
  config,
}: useProductReportsByVendorsProps): useProductReportsByVendorsResponse => {
  const { data, error, mutate } = useSWR<
    ApiResponse<ExportsAggregatorResponse[]>
  >(
    catalogId ? ENDPOINTS.listOfProductReportsByVendor({ catalogId }) : null,
    () => getListOfProductReportsByVendor({ catalogId }),
    config
  );

  return {
    reports: data?.data,
    isError: error || data?.data === null,
    isLoading: !error && !data?.data,
    mutate,
  };
};

export default useProductReportsByVendors;
