import fetcher from 'api/fetcher';
import { ApiResponse, ExportsAggregatorResponse } from 'models';
import { apiErrorBoundary, parseApiResponse } from 'utils/api';
import dayjs from 'dayjs';
import { DATE_TIME_SHORT_FORMAT } from 'constants/common';

export const ENDPOINTS = {
  listOfProductReportsByVendor: ({
    catalogId,
  }: {
    catalogId: string;
  }): string => `/products/export/vendor/${catalogId}`,
};

type GetListOfProductReportsByVendorProps = {
  catalogId: string;
};

export const getListOfProductReportsByVendor = async ({
  catalogId,
}: GetListOfProductReportsByVendorProps): Promise<
  ApiResponse<ExportsAggregatorResponse[]>
> =>
  apiErrorBoundary(async () => {
    try {
      const productsUrl = ENDPOINTS.listOfProductReportsByVendor({
        catalogId,
      });

      const response = await fetcher<ExportsAggregatorResponse[]>(productsUrl);

      response.data.forEach((report) => {
        report.createdAt = dayjs(report.createdAt).format(
          DATE_TIME_SHORT_FORMAT
        );
      });

      const maxRowsOnThePage = 10;
      const newData = response.data.slice(0, maxRowsOnThePage);

      return parseApiResponse({ ...response, data: newData });
    } catch (error) {
      // Handle the error here, e.g., log it or throw a custom error
      console.error('Error fetching data:', error);
      throw new Error('Error fetching data: ' + error);
    }
  }, `Error fetching the list of ProductReports by a specific vendor`);
