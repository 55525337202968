import React from 'react';
import { Breadcrumbs, Button, Heading } from '@tamo-marketplace/genie';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import { Tab, Tabs } from '@tamannabit/design-system.components.tabs';
import styled from 'styled-components';
import {
  PRODUCT_INVENTORY_BUTTOM,
  PRODUCT_INVENTORY_TITLE,
  CATALOG_SEARCH_PLACEHOLDER,
} from 'constants/textResources';
import CatalogSection from 'components/productListing/CatalogSection';
import SearchInput from 'components-shared/header/SearchInput';
import ProductSection from 'components/productListing/ProductSection';
import ExportSection from './export/ExportSection';

const StyledSearchInput = styled(SearchInput)(({ theme: { space } }) => ({
  marginRight: space[8],
  width: '200px',
  height: '40px',
}));

type ActionProps = {
  searchTerm: string;
  handleSearch: (value: string) => void;
  handleClick: () => void;
};

export const Action = ({
  searchTerm,
  handleSearch,
  handleClick,
}: ActionProps) => {
  const {
    theme: { space },
  } = useTheme();

  return (
    <>
      <StyledSearchInput
        currentSearchTerm={searchTerm}
        handleSearch={handleSearch}
        placeholderText={CATALOG_SEARCH_PLACEHOLDER}
      />
      <Button
        iconName="ArrowRight"
        variant="primary"
        css={{ marginLeft: space[8], width: '124px', height: '40px' }}
        onClick={handleClick}
      >
        {PRODUCT_INVENTORY_BUTTOM}
      </Button>
    </>
  );
};

export const Title = ({ catalogId }: { catalogId?: string }) => (
  <Heading data-testid="catalog-page-title">
    {catalogId
      ? `${catalogId?.toUpperCase()} ${PRODUCT_INVENTORY_TITLE}`
      : PRODUCT_INVENTORY_TITLE}
  </Heading>
);

export const BreadCrumbs = ({ catalogId }: { catalogId: string }) => {
  const catalogURI = { label: 'Catalog', uri: '/' };
  const vendorsURI = { label: 'Vendors', uri: '/vendors' };
  const vendorNameURI = {
    label: catalogId,
    uri: 'javascript: history.go(-1)',
  };

  const importBreadcrumbs = [vendorsURI, vendorNameURI, catalogURI];

  return <Breadcrumbs routes={importBreadcrumbs} />;
};

export const TabNavigation = ({
  tabIndex,
  handleTabChange,
}: {
  tabIndex: number;
  handleTabChange: (
    e: React.SyntheticEvent<Element, Event>,
    index: number
  ) => void;
}) => (
  <Tabs
    selectedTab={tabIndex}
    onChange={handleTabChange}
    data-testid="catalog-page-tabs"
  >
    <Tab label="Products" data-testid="catalog-page-tab-products" />
    <Tab label="Imports" data-testid="catalog-page-tab-activity" />
    <Tab label="Exports" data-testid="catalog-page-tab-exports" />
  </Tabs>
);

export const TabSection = ({
  searchTerm,
  tabIndex,
}: {
  searchTerm: string;
  tabIndex: number;
}) => (
  <>
    {tabIndex === 0 && <ProductSection searchTerm={searchTerm} />}
    {tabIndex === 1 && <CatalogSection searchTerm={searchTerm} />}
    {tabIndex === 2 && <ExportSection searchTerm={searchTerm} />}
  </>
);
