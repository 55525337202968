import React, { useCallback, useMemo, useState } from 'react';
import { EmptyState, StateType } from '@tamo-marketplace/genie';
import { BaseTableStyle } from 'styles';
import { Styleable } from 'models/utils';
import {
  EMPTY_RESULTS_SUBTITLE,
  EMPTY_RESULTS_TITLE,
} from 'constants/textResources';
import { Column } from 'react-table';
import { PaginationFields, ProductsAggregatorResponse } from 'models';
import useProductsList from 'hooks/products/useProductsList';
import ProductTable, {
  FetchParameters,
} from 'components-shared/table/BaseTable';
import { useRouter } from 'next/router';
import { ProductDetailsRoute } from 'constants/routes';

const NoDataEmptyState = () => (
  <EmptyState
    stateType={StateType.EMPTY_RESULTS}
    title={EMPTY_RESULTS_TITLE}
    subtitle={EMPTY_RESULTS_SUBTITLE}
  />
);

type TableProps = {
  searchTerm?: string;
} & Styleable;

const ProductListingTable = ({ style, searchTerm, ...props }: TableProps) => {
  const { query, push } = useRouter();
  const { catalogId } = query as { catalogId: string };
  const [fetchParameters, setFetchParameters] = useState<PaginationFields>({
    page: 0,
    size: 10,
  });
  const { data, isLoading } = useProductsList({
    pagination: fetchParameters,
    vendorRef: catalogId,
  });

  const handleClickProduct = useCallback(
    (product: ProductsAggregatorResponse) => {
      const { id } = product;
      push({
        pathname: ProductDetailsRoute + `/${id}`,
      });
    },
    []
  );

  const tableColumns: Column<ProductsAggregatorResponse>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Brand',
        accessor: 'brandName',
      },
      {
        Header: 'SKU',
        accessor: 'sku',
      },
      {
        Header: 'Variants',
        accessor: 'numberOfVariants',
      },
    ],
    []
  );

  const fetchData = useCallback(
    async (parameters: FetchParameters<ProductsAggregatorResponse>) => {
      const {
        sortBy,
        pageIndex: page,
        pageSize: size,
        filter,
        searchTerm,
      } = parameters;
      const sort = sortBy
        ? `${sortBy.id},${sortBy.desc ? 'desc' : 'asc'}`
        : undefined;
      setFetchParameters({ sort, page, size, filter, searchTerm });
    },
    []
  );

  return (
    <BaseTableStyle sx={style} data-testid="products-table-wrapper" {...props}>
      <ProductTable<ProductsAggregatorResponse>
        fetchData={fetchData}
        loading={isLoading}
        totalRows={data?.totalElements}
        tableOptions={{
          data: data?.data,
          columns: tableColumns,
          manualPagination: true,
          manualSortBy: true,
          autoResetPage: false,
          autoResetSortBy: false,
          pageCount: data?.totalPages,
        }}
        NoDataEmptyState={NoDataEmptyState}
        searchTerm={searchTerm}
        onRowClick={(product) => {
          handleClickProduct(product);
        }}
      />
    </BaseTableStyle>
  );
};

export default ProductListingTable;
