import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass/styled-components';
import CenteredModal from 'components-shared/modal/CenteredModal';
import Spinner from 'components-shared/Spinner';
import Icon from 'components-shared/icon/Icon';
import {
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_1,
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_2,
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_3,
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_DOWNLOADED,
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_PREPARING,
  PRODUCT_INVENTORY_DOWNLOAD_MODAL_TITLE,
} from 'constants/textResources';
import { StyleColor } from 'models/utils';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 94px 0;
`;

const IconContainer = styled.div`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[32]};
  `};
  i {
    font-size: 56px;
  }
`;

const ContentContainer = styled(Flex)`
  ${({ theme: { space, fontSizes, fontWeights } }) => `
    margin: ${space[12]} 0px ${space[64]};
    div {
      font-style: normal;
      font-weight: ${fontWeights.regular};
      font-size: ${fontSizes[18]};
      line-height: 32px;
      text-align: center;
    }
  `};
  flex-direction: column;
  width: 661px;
`;

const LoadingContainer = styled(Flex)``;
const CustomCenteredModal = styled(CenteredModal)`
  div {
    width: 500px !important;
  }
`;

type ModalProps = {
  isDownloadFinished: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

const DownloadModal = ({
  isDownloadFinished,
  isModalOpen,
  setIsModalOpen,
}: ModalProps) => {
  const {
    theme: { colors, fontSizes, fontWeights },
  } = useTheme();

  return (
    <CustomCenteredModal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      style={{ maxWidth: 701 }}
    >
      <Container>
        <IconContainer>
          <Icon
            name="file_analysing"
            size={20}
            color={colors.summer100 as StyleColor}
          />
        </IconContainer>
        <Text fontSize={fontSizes[36]} fontWeight={fontWeights.bold}>
          {PRODUCT_INVENTORY_DOWNLOAD_MODAL_TITLE}
        </Text>
        {!isDownloadFinished ? (
          <ContentContainer mt={4}>
            <div>{PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_1}</div>
            <div>{PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_2}</div>
          </ContentContainer>
        ) : (
          <ContentContainer mt={4}>
            <div>{PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_3}</div>
          </ContentContainer>
        )}

        {!isDownloadFinished ? (
          <LoadingContainer>
            {PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_PREPARING + ' '}
            <Spinner
              size={16}
              color={colors.summer100 as StyleColor}
              relative
            />
          </LoadingContainer>
        ) : (
          <LoadingContainer>
            {PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_DOWNLOADED + ' '}
            <Icon
              name="circular_check"
              margin="0 0 0 10px"
              size="10px"
              color={colors.summer100 as StyleColor}
            />
          </LoadingContainer>
        )}
      </Container>
    </CustomCenteredModal>
  );
};

export default DownloadModal;
