import React from 'react';
import ExportListingTable from './ExportListingTable';

interface Props {
  searchTerm: string;
}

const ExportSection = ({ searchTerm }: Props) => (
  <ExportListingTable searchTerm={searchTerm} />
);

export default ExportSection;
