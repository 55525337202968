import React from 'react';
import MetricsHeader from './MetricsHeader';
import ProductListingTable from './ProductListingTable';

interface Props {
  searchTerm: string;
}

const ProductSection = ({ searchTerm }: Props) => (
  <>
    <MetricsHeader />
    <ProductListingTable searchTerm={searchTerm} />
  </>
);

export default ProductSection;
