import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { space } }) => css`
    max-width: 400px;
    margin: ${space[30]};
    padding: ${space[20]};
    text-align: center;
  `
);

export const ButtonWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-top: ${space[30]};
  `
);
