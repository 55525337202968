import styled, { css } from 'styled-components';
import ProductDownloadSection from './ProductDownloadSection';

const StyledWrapper = styled.div(
  ({ theme: { space } }) => css`
    justify-content: flex-end;
    margin-bottom: ${space[8]};
    margin-top: ${space[40]};
  `
);

const MetricsHeader = () => (
  <StyledWrapper>
    <ProductDownloadSection />
  </StyledWrapper>
);

export default MetricsHeader;
