import CenteredModal from 'components-shared/modal/CenteredModal';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@tamo-marketplace/genie';
import { ButtonWrapper, Container } from './ExportModal.styles';
import { ExportsAggregatorResponse } from 'models';
import { Text } from '@tamannabit/design-system.components.text';
import { Heading } from '@tamannabit/design-system.components.heading';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: ExportsAggregatorResponse | null;
}

const ExportModal = ({ isOpen, onClose, data }: ExportModalProps) => {
  const { push } = useRouter();
  const {
    theme: { space },
  } = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const exportReportHandler = () => {
    setIsLoading(true);
    push({
      pathname: data?.reportUrl,
    });

    setTimeout(() => {
      setIsLoading(false);
      onClose();
    }, 1000);
  };

  return (
    <CenteredModal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Heading variant="heading3" asElement="h2">
          Export Report
        </Heading>
        <Heading
          variant="heading6"
          asElement="h3"
          style={{ marginTop: space[10], marginBottom: space[30] }}
        >
          Id {data?.id}: created at {data?.createdAt}
        </Heading>
        <Text variant="text_s">
          Export the list of product reports by a specific vendor by downloading
          the csv file.
        </Text>

        <ButtonWrapper>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={exportReportHandler}
            loading={isLoading}
          >
            {isLoading ? 'Downloading' : 'Download'}
          </Button>
        </ButtonWrapper>
      </Container>
    </CenteredModal>
  );
};

export default ExportModal;
