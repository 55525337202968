import React from 'react';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import Icon from 'components-shared/icon/Icon';
import { StyleColor } from 'models/utils';
import { StyledButton, StyledText } from './ColumnNameContent.styles';
import { getFileNameFromPath } from 'utils/file';

type ExportReportNameContentProps = {
  onClick: () => void;
  text: string;
};

const ColumnNameContent = ({ onClick, text }: ExportReportNameContentProps) => {
  const {
    theme: { colors, space },
  } = useTheme();

  if (!text) {
    return (
      <StyledText>
        <span>No report</span>
        <Icon
          name="circular_error"
          size={12}
          style={{ ml: space[8] }}
          color={colors.neutral80 as StyleColor}
        />
      </StyledText>
    );
  }

  const filename = getFileNameFromPath(text) || 'file';

  return (
    <StyledButton onClick={onClick}>
      <span className="button-text">
        {decodeURIComponent(filename) ?? 'Download file'}
      </span>
      <Icon
        name="download"
        size={12}
        style={{ ml: space[8] }}
        color={colors.neutral120 as StyleColor}
      />
    </StyledButton>
  );
};

export default ColumnNameContent;
