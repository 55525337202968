import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Icon as IconComponent } from '@tamo-marketplace/genie';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import {
  getInventoryCsvFile,
  getProductsCsvURL,
  requestProductsCsv,
} from 'api/services/productService';
import { displayNotification } from 'components-shared/notifications';
import { isString } from 'utils/primitives';
import { Log } from 'utils/logs';
import useCancelToken from 'hooks/useCancelToken';
import {
  PRODUCT_INVENTORY_EXPORT_INVENTORY_LABEL,
  PRODUCT_INVENTORY_EXPORT_PRODUCTS_LABEL,
} from 'constants/textResources';
import { downloadFileFromURL, saveBlob } from 'utils/io';
import { useRouter } from 'next/router';
import DownloadModal from './DownloadModal';

const ClickableSpan = styled.span(({ theme: { colors, fontWeights } }) => ({
  display: 'Flex',
  fontWeight: fontWeights.bold,
  transition: 'color 0.3s ease',
  cursor: 'pointer',
  color: colors.neutral120,
  '&:hover': {
    color: colors.neutral100,
  },
}));

const ProductExportSection = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSetInterval, setModalSetInterval] =
    useState<NodeJS.Timeout | null>();
  const [isDownloadFinished, setIsDownloadFinished] = useState(false);
  const { getCancelToken } = useCancelToken();
  const {
    theme: { space, colors },
  } = useTheme();
  const { query } = useRouter();
  const { catalogId } = query as { catalogId: string };

  useEffect(() => {
    if (modalSetInterval && !isModalOpen) {
      clearInterval(modalSetInterval);
      setIsDownloadFinished(false);
    }
  }, [modalSetInterval, isModalOpen]);

  const handleInventoryExport = async () => {
    try {
      setIsModalOpen(true);

      const { data, wasCanceled, hasError, error } = await getInventoryCsvFile(
        {
          cancelToken: getCancelToken(),
        },
        catalogId
      );

      if (wasCanceled) {
        setIsModalOpen(false);
        return;
      }

      if (hasError) {
        throw error || 'Failed to download file';
      }

      const blob = new Blob([data?.fileData as Blob], {
        type: 'text/csv;charset=utf-8;',
      });
      saveBlob(blob, data?.fileName || 'product_inventory.csv');
      setIsDownloadFinished(true);
    } catch (error) {
      setIsModalOpen(false);
      displayNotification('error', {
        message: error && isString(error) ? error : 'Failed to download file',
      });

      const message =
        'Method handleInventoryExport - Failed to save inventory CSV file, due to:';
      Log.error(message, error);
    }
  };

  const handleProductExport = async () => {
    try {
      setIsModalOpen(true);

      const { data, wasCanceled, hasError, error } = await requestProductsCsv(
        {
          cancelToken: getCancelToken(),
        },
        catalogId
      );

      const id = data?.id ? data?.id : '';

      /**
       * Verify (get) if the file is ready to be download every 10 seconds
       * if have the reportURL and status is Finished, we start the download
       * and the flag to change the text/layout
       **/
      if (id) {
        const productsRefreshStatusInterval = setInterval(async () => {
          const result = await getProductsCsvURL(id, {
            cancelToken: getCancelToken(),
          });

          if (result && result.data) {
            const { reportUrl, status } = result.data;

            if (reportUrl && status === 'FINISHED') {
              downloadFileFromURL(reportUrl);
              setIsDownloadFinished(true);
              clearInterval(productsRefreshStatusInterval);
            }
          }
        }, 10000);

        setModalSetInterval(productsRefreshStatusInterval);

        if (wasCanceled) {
          setIsModalOpen(false);
          return;
        }

        if (hasError) {
          throw error || 'Failed to download file';
        }
      }

      if (wasCanceled) {
        setIsModalOpen(false);
        return;
      }
      if (hasError) {
        throw error || 'Failed to request file';
      }
    } catch (error) {
      setIsModalOpen(false);
      displayNotification('error', {
        message: error && isString(error) ? error : 'Failed to download file',
      });

      const message =
        'Method handleProductExport - Failed to save product CSV file, due to:';
      Log.error(message, error);
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end">
        <Flex mr={space[8]}>
          <ClickableSpan onClick={handleProductExport}>
            <IconComponent name="Download" css={{ marginRight: 5 }} />
            {PRODUCT_INVENTORY_EXPORT_PRODUCTS_LABEL}
          </ClickableSpan>
        </Flex>
        <Flex color={colors.neutral40}>|</Flex>
        <Flex ml={space[8]}>
          <ClickableSpan onClick={handleInventoryExport}>
            <IconComponent name="Download" css={{ marginRight: 5 }} />
            {PRODUCT_INVENTORY_EXPORT_INVENTORY_LABEL}
          </ClickableSpan>
        </Flex>
      </Flex>

      {isModalOpen && (
        <DownloadModal
          isDownloadFinished={isDownloadFinished}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default ProductExportSection;
