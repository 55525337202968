import { ExportsStatus } from 'models';
import React from 'react';
import { StyledText } from './ColumnStatus.styles';

type ColumnStatusProps = { status: ExportsStatus };

const ColumnStatus = ({ status }: ColumnStatusProps) => {
  return (
    <StyledText variant="text_xs" status={status}>
      {status}
    </StyledText>
  );
};

export default ColumnStatus;
