import styled, { css } from 'styled-components';
import { Text } from '@tamannabit/design-system.components.text';
import { ExportsStatus } from 'models';
import { ThemeColorsType } from '@tamannabit/design-system.foundations.theme';

export type VariantProps = {
  colors: ThemeColorsType;
};

const cssModifiers = {
  statusFinished: ({ colors }: VariantProps) => css`
    background-color: ${colors.summer100};
    color: ${colors.neutral00};
  `,

  statusFinishedWithErrors: ({ colors }: VariantProps) => css`
    background-color: ${colors.blush100};
    color: ${colors.neutral00};
  `,

  statusProcessing: ({ colors }: VariantProps) => css`
    background-color: ${colors.bee40};
    color: ${colors.neutral120};
  `,

  statusScheduled: ({ colors }: VariantProps) => css`
    background-color: ${colors.winter40};
    color: ${colors.neutral120};
  `,
};

export const StyledText = styled(Text)<{ status?: ExportsStatus }>(
  ({ status, theme: { colors, space } }) => css`
    margin: 1em 0;
    padding: ${space[2]} ${space[16]};
    border-radius: 16px;
    display: flex;
    align-items: center;
    display: inline-block;

    ${status === 'FINISHED' && cssModifiers.statusFinished({ colors })};
    ${status === 'FINISHED_WITH_ERRORS' &&
    cssModifiers.statusFinishedWithErrors({ colors })};
    ${status === 'PROCESSING' && cssModifiers.statusProcessing({ colors })};
    ${status === 'SCHEDULED' && cssModifiers.statusScheduled({ colors })};
  `
);
