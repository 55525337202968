import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { SxStyleProp } from 'rebass/styled-components';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import Icon from 'components-shared/icon/Icon';
import { zIndex } from 'styles/theme';

const ButtonClose = styled(Box)`
  position: absolute;
  right: 16px;
  top: 16px;
  &:hover {
    cursor: pointer;
  }
`;
interface CenteredModalProps {
  isOpen: boolean;
  onClose: () => void;
  style?: SxStyleProp;
}

const CenteredModal: React.FC<CenteredModalProps> = ({
  isOpen = false,
  onClose,
  children,
  style,
}) => {
  const isMobile = useIsTouchDevice();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

      if (mac) {
        document.body.style.paddingRight = '6px';
      } else {
        document.body.style.paddingRight = '15px';
      }
    }
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      closeTimeoutMS={200}
      style={{
        overlay: {
          display: 'flex',
          zIndex: zIndex.modal,
          backgroundColor: 'rgba(190, 190, 190, 0.6)',
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          border: 'none',
          overflow: 'auto',
          position: 'relative',
          inset: 'unset',
          boxShadow: '0 20px 60px 0 rgba(0, 0, 0, 0.15)',
          padding: 0,
          maxWidth: isMobile ? '100%' : '792px',
          boxSizing: isMobile ? 'border-box' : 'border-box',
          height: isMobile ? '100%' : 'min-content',
          borderRadius: isMobile ? '0' : '8px',
          ...style,
        },
      }}
    >
      <ButtonClose onClick={onClose} data-testid="modal-btn-close">
        <Icon name="close" title="Close modal" size={24} />
      </ButtonClose>
      <Box data-testid="modal-wrapper">{children}</Box>
    </Modal>
  );
};

export default CenteredModal;
