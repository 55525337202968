import styled, { css } from 'styled-components';

export const StyledButton = styled.button(
  ({ theme: { fontWeights, space } }) => css`
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: ${fontWeights.bold};
    opacity: 1;
    transition: opacity 0.2s ease;
    cursor: pointer;
    text-align: left;
    margin: 1em 0;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }

    & div.button-text {
      text-decoration: underline;
      margin-right: ${space[6]};
    }

    & .flip-icon {
      text-decoration: none;
    }

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
    }
  `
);

export const StyledText = styled.div(
  ({ theme: { colors, fontSizes } }) => css`
    margin: 1em 0;
    display: flex;
    align-items: center;
    color: ${colors.neutral80};
    font-size: ${fontSizes[14]};
  `
);
